import React, {Component} from 'react';
import PropTypes from 'prop-types';
import upperSymbols from "./images/upperSimbolsBBC.png";
import logo from './images/walsLogo.png';
import logo2 from './images/walsLogo.png';
import TextField from "@material-ui/core/TextField";
import {createMuiTheme} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

function isDate18orMoreYearsOld(day, month, year) {
    return new Date(year+18, month-1, day) <= new Date();
}

export default class BirthdayConsent extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            birth:'',
            error: false,
        };
    }

    componentDidMount() {

    }

    handleChange = name => (event) => {
        var context = this;
        this.setState({
            [name]: event.target.value,
            error: false,
        });
    };

    onAgeConsent = () => {
        if (this.state.birth){
            var date = new Date(this.state.birth);
            if (isDate18orMoreYearsOld(date.getDay(), date.getMonth(), date.getFullYear())){
                this.props.handleBirthConsent(this.state.birth);
            }else {
                this.setState({
                    error: true,
                })
            }
        }
    };

    render() {

        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#000000',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#ffffff'
                },
                secondary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#fff',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#17BA42'
                }
            },
            typography: { useNextVariants: true },
        });
        return (
            <MuiThemeProvider theme={theme}>
                <div>
                 {/*   <div className={'upper'}>
                        <p>
                            *APLICA TYC, CONSÚLTELOS <i className="ion ion-logo-facebook"></i>@BBCCERVECERIA, PROHIBESE EL EXPENDIO DE BEBIDAS EMBRIAGANTES A MENORES DE EDAD. EL EXECESO DE ALCOHOL ES PERJUDICIAL PARA LA SALUD.
                            <img className={'upperSymbols'} alt={'upperSymbols'} src={upperSymbols}/>
                        </p>
                    </div>*/}
                    <div  className={'logoContainer'}>
                        <img className={'logo logoValidator'} src={logo}/>
                    </div>
                    <p className={'startLoginPTitle birthConsentTitle'}> {
                        'VERIFIQUE SUA IDADE'
                    } </p>

                    <TextField
                        value={this.state.birth}
                        onChange={this.handleChange('birth')}
                        error={this.state.error}
                        label=""
                        type="date"
                        className="fieldModal loginDetails birthConsentField"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                            classes: {
                                root: '',
                                focused: 'inputCustomLogin',
                                error: 'errorInput'
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: 'inputCustomLogin',
                                focused: 'inputCustomLogin',
                                notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                error: 'errorInput'
                            },
                        }}
                    />
                    <Button onClick={this.onAgeConsent}  variant="contained" color="primary"
                            className={"loginButton modalButton loginStartButton buyButton2 birthConsentButton"}>
                        ENTRAR
                    </Button>

                </div>
            </MuiThemeProvider>

        )
    }
}