import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './App.css';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import SlideUpPanel from "./SlideUpPanel";
import firebase from './firebase';
import Loader from "./Loader";
import $ from 'jquery';
import AppComponent from "./AppComponent";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Cookies from "universal-cookie/es6";
import BirthdayConsent from "./BirthdayConsent";
import Validator from "./Validator";
import Ranking from "./Ranking";
import RankingComponent from "./RankingComponent";

const jss = create({
    ...jssPreset(),
    // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
    insertionPoint: document.getElementById('jss-insertion-point'),
});


export default class App extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastName: '',
            email: '',
            uid: '',
            login: false,
            fid: '',
            birthConsent: false,
            friendUID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
        };
    }

    componentDidMount() {

        const cookies = new Cookies();
        var db = firebase.firestore();
        var campRef = db.collection('campaigns').doc('walsviral');
        var hasUsed = cookies.get('hasUsed2');
        var birthConsent = cookies.get('birthConsent');

        if (birthConsent){

            this.setState({
                birthConsent: true,
            })
        }

       /* db.runTransaction(function (transaction) {
            return transaction.get(campRef).then(function (camp) {
                if (!camp.exists) {
                    throw "Document does not exist!";
                }
                var newTraffic = camp.data().traffic + 1;

                transaction.update(campRef, {traffic: newTraffic});
                return newTraffic;

            });
        }).then(function (newPopulation) {

        }).catch(function (err) {
            // This will be an "population is too big" error.
            console.error(err);
        });

        if (!hasUsed) {
            let d = new Date();
            d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
            cookies.set('hasUsed2', true, {expires: d});
            db.runTransaction(function (transaction) {
                return transaction.get(campRef).then(function (camp) {
                    if (!camp.exists) {
                        throw "Document does not exist!";
                    }
                    var newTrafficUsers = camp.data().trafficUsers + 1;

                    var newConsumerShare = camp.data().consumerShare;
                    var newBrandShare = camp.data().brandShare;

                    if (context.state.friendUID) {
                        newConsumerShare = newConsumerShare + 1
                    } else {
                        newBrandShare = newBrandShare + 1;
                    }

                    /!*var facebookVis = camp.data().facebookVis;
                    var instagramVis = camp.data().instagramVis;
                    var storiesVis = camp.data().storiesVis;
                    var mailingVis = camp.data().mailingVis;



                    var friendUID = context.state.friendUID;
                    if (friendUID) {
                        if (friendUID === 'facebook'){
                            facebookVis = facebookVis + 1;
                            newBrandShare = newBrandShare + 1;
                        }
                        else if(friendUID === 'instagram'){
                            instagramVis = instagramVis + 1;
                            newBrandShare = newBrandShare + 1;
                        }
                        else if(friendUID === 'stories'){
                            storiesVis = storiesVis + 1;
                            newBrandShare = newBrandShare + 1;
                        }
                        else if(friendUID === 'mailing'){
                            mailingVis = mailingVis + 1;
                            newBrandShare = newBrandShare + 1;
                        }
                        else{
                            newConsumerShare = newConsumerShare + 1
                        }
                    } else {
                        newBrandShare = newBrandShare + 1;
                    }*!/



                    transaction.update(campRef, {
                        trafficUsers: newTrafficUsers,
                        consumerShare: newConsumerShare,
                        brandShare: newBrandShare,
                       /!* facebookVis: facebookVis,
                        instagramVis: instagramVis,
                        storiesVis: storiesVis,
                        mailingVis: mailingVis*!/
                    });
                    return newTrafficUsers;

                });
            }).then(function (newPopulation) {

                if (context.state.friendUID){
                    if (context.state.friendUID !== 'facebook' && context.state.friendUID !== 'instagram' && context.state.friendUID !== 'stories' && context.state.friendUID !== 'mailing') {
                        var friendRef = db.collection('users').doc(context.state.friendUID);
                        db.runTransaction(function (transaction) {
                            return transaction.get(friendRef).then(function (doc) {
                                if (!doc.exists) {
                                    throw "Document does not exist!";
                                }

                                var newShare = doc.data().shareTo + 1;
                                transaction.update(friendRef, {shareTo: newShare});
                            });
                        })

                    }
                }



            }).catch(function (err) {
                // This will be an "population is too big" error.
                console.error(err);
            });
        }*/

        var context = this;
        firebase.auth().getRedirectResult().then(function(result) {
            $("#loading").fadeOut();
            $("#loading .object").delay(700).fadeOut("slow");
            $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);

            if (result.credential) {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var token = result.credential.accessToken;
                // ...
            }
            // The signed-in user info.

            var user = result.user;
            var splitedString = user.displayName.split(' ');


            var firstName = splitedString[0];
            var lastName = '';

            for (var i=1; i<splitedString.length; i++){
                if (i === 1){
                    lastName = splitedString[i];
                }
                else {
                    lastName = lastName + ' ' + splitedString[i];
                }
            }
            context.setState({
                name: firstName,
                lastName: lastName,
                email: user.email,
                uid: user.uid,
                login: true,
                fid: user.providerData[0].uid
            });


        }).catch(function(error) {

            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;

            /*setTimeout(()=> {context.setState({login: 2})},700);*/

            // ...
        });
    }

    handleChangeLogin = () =>{
        this.setState({
            login: !this.state.login,
        })
    };

    handleBirthConsent = (birthday) => {
        const cookies = new Cookies();
        var birthConsent = cookies.get('birthConsent');
        let d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        cookies.set('birthConsent', true, {expires: d});
        cookies.set('birthday', birthday, {expires: d});
        this.setState({
            birthConsent: true,
        })
    };


    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#3591FF',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });
        return (
            <Router>
                <StylesProvider jss={jss}>
                    <MuiThemeProvider theme={theme}>
                        <div className="App">
                            <div id="loading">
                                <div id="loading-center">
                                    <div id="loading-center-absolute">
                                        <div className="object" id="object_four"></div>
                                        <div className="object" id="object_three"></div>
                                        <div className="object" id="object_two"></div>
                                        <div className="object" id="object_one"></div>
                                    </div>
                                </div>
                            </div>
                            <Switch>
                                <Route
                                    path='/validator/:id'
                                    render={(props) =>
                                        <div className={''}>
                                            <Validator/>
                                        </div>}
                                />
                               {/* <Route
                                    path='/ranking'
                                    render={(props) =>
                                        <div className={''}>
                                            <RankingComponent/>
                                        </div>}
                                />*/}
                                <Route
                                    path='/privacypolicy'
                                    render={(props) =>
                                        <div className={'privacyPolicy'}>
                                            <p>
                                                REGULAMENTO BLACK FRIDAY

                                                1. DADOS DA ORGANIZADORA:
                                                ZXPERIENCES BARES E RESTAURANTES LTDA. (“Organizadora”), Av. Antarctica, n° 1891, parte,
                                                CEP 13820-000, na cidade de Jaguariúna, Estado de São Paulo, inscrita no CNPJ/MF sob o no
                                                08.755.044/0001-33, e-mail: reservas@wals.com.br
                                                2. DADOS DAS FILIAIS PARTICIPANTES:
                                                Ateliê Wals
                                                3. NOME DA PROMOÇÃO:
                                                Promoção: Black Friday
                                                4. MODALIDADE:
                                                Promoção simples não enquadrada na Lei no 5.768 de 1971.
                                                5. ÁREA DE EXECUÇÃO DO PLANO:
                                                Município de Belo Horizonte.
                                                6. DATA DE INÍCIO E TÉRMINO DA PROMOÇÃO:
                                                De 27/11/2019 a 01/12/2019 ou até durarem os estoques.
                                                7. PERÍODO DE PARTICIPAÇÃO:
                                                De acordo com os horários de funcionamento do Bar (indicação abaixo) do dia 27/11/2019 até o dia
                                                01/12/2019, horário de Brasília.
                                                8. PRAZO DE EXECUÇÃO:
                                                4 dias.
                                                9. OBJETO DA PROMOÇÃO:
                                                GERAR TRIAL
                                                10. DESCRIÇÃO DETALHADA DA OPERAÇÃO:
                                                10.1) Apenas pessoas maiores de 18 anos poderão participar desta promoção, sem obrigação de
                                                compra e de forma gratuita, desde que apresente o seu documento de identidade válido ao atendente
                                                na Ateliê Wals.
                                                10.2) O intuito da Promoção é o Participante ganhar o prêmio e indicar um amigo (Beneficiários) para
                                                que este ganhe o Prêmio. Participante e Beneficiários deverão ser maiores de 18 anos.
                                                10.3) Os Beneficiários receberão um e-mail único. Uma vez recebido o e-mail e validado o código,
                                                nenhum participante e/ou Beneficiário poderá participar novamente da promoção, mesmo que receba
                                                outro e-mail de outro Participante.
                                                10.6) O Cupom poderá ser trocado pelo Prêmio apenas dentro do (i) Período de Participação e
                                                somente (ii) no Ateliê Wals

                                                10.7) Para receber o Prêmio, o Benecifiário deverá mostrar o e-mail da promoção ao atendente do
                                                Ateliê Wals e apresentar o seu documento de identidade, com CPF, momento em que o atendente
                                                fornecerá um código válido para o beneficiário resgatar o prêmio desde que corresponde ao
                                                Beneficiário e que este é maior do que 18 anos.
                                                10.8) Nenhum Prêmio será entregue a Beneficiários que sejam menor do que 18 anos, ainda que
                                                estejam acompanhados por um responsável e/ou que o Cupom seja válido.
                                                10.9) O prêmio deverá ser consumido no Ateliê Wals
                                                10.10) O prêmio não será convertido em dinheiro.
                                                10.11) A Promoção está limitada à duração dos estoques.
                                                10.12) A Promoção é válida apenas para o chope de 340ml

                                                11. PRÊMIO:

                                                11.1) Os primeiros 2.000 (dois mil) convidados participantes que preencherem o formulário
                                                (doravante, os &quot;Beneficiários&quot;) receberão um e-mail (doravante, o &quot;Cupom&quot; e no plural, &quot;Cupons&quot;), no
                                                qual deverá ser mostrado ao atendente do Ateliê Wals, para trocá-lo por um chope da marca &quot;Wals&quot;
                                                na faixa de R$10,00 (dez reais) a R$ 12,00 (doze reais) e de 340 ml gratuitamente, apenas na
                                                unidade Ateliê Wals.
                                                11.2) A Promoção é aplicável somente no Ateliê Wals, R. Gabriela de Melo, 566 - Olhos D&#39;Água, Belo
                                                Horizonte - MG, 30390-080. Horário de funcionamento: Terça a quinta das 18h à 0h,
                                                Sexta das 18h às 1h, Sábado das 12h às 00h30 e Domingo das 12h às 18h.

                                                12. USO DE INFORMAÇÕES PESSOAIS
                                                12.1) A Organizadora irá coletar as seguintes informações pessoais dos Participantes e
                                                dos Beneficiários, sem armazenar nada: (i) CPF; (ii) e-mail; (iii) nome e sobrenome; e,
                                                (iv) data de aniversário.
                                                12.2) Os participantes e os Beneficiários, ao participar da Promoção, reconhecem que a
                                                Organizadora poderá coletar as informações inseridas ativamente por eles no momento do cadastro.
                                                12.3) Os participantes declaram e reconhecem que eles têm a autorização dos Beneficiários para
                                                lhes enviar o link para participar da Promoção. Toda e qualquer responsabilidade que possa surgir
                                                em decorrência de eventual violação a esta condição, direta ou indiretamente, é de responsabilidade
                                                do Participante, eximindo-se a Organizadora.
                                                12.4) O fornecimento de dados pelos Participantes e Beneficiários da promoção é voluntário, mas é
                                                um pré-requisito para participar da promoção.
                                                12.5) Os Participantes e os Beneficiários podem pedir para que seus dados sejam excluídos da base
                                                de dados da Organizadora enviando um e-mail, reservas@wals.com.br

                                                13. PRÊMIO NÃO VINCULADO

                                                13.1) Os Prêmios não retirados pelos Beneficiários continuarão a ser distribuídos pela Organizadora.
                                                14. ACEITAÇÃO DO REGULAMENTO
                                                14.1) A participação nesta Promoção implica no pleno conhecimento e na total aceitação deste
                                                Regulamento.

                                                14.2) Para participar da promoção e eventualmente recebendo o prêmio, os participantes e/ou
                                                beneficiários entendem que nenhuma outra obrigação a organizadora lá fora é o aqui determinado.
                                                15. LEGISLAÇÃO APLICÁVEL
                                                15.1) Este Regulamento será regido e interpretado de acordo com a legislação em vigor na República
                                                Federativa do Brasil.

                                                São Paulo, 19 de novembro de 2019.
                                                ZXPERIENCES BARES E RESTAURANTES LTDA.

                                                CNPJ: 08.755.044/0001-33


                                                REGRAS DE UTILIZAÇÃO DA CAMPANHA BLACK FRIDAY-ZXPERIENCES

                                                1. OBJETO E ACEITAÇÃO

                                                1.1) As presentes Regras de Utilização (“Regras”) têm como objetivo regular o acesso e utilização,
                                                por você, Usuário (“Você” e “Usuário”), da campanha (“Black Friday”) fornecida e gerenciada pela
                                                Zxperiences Bares e Restaurantes Ltda. (“Zxperiences”), em estabelecimentos comerciais,
                                                conjuntamente com empresa parceira (“Parceira”), que disponibiliza esta estrutura.

                                                1.1.1) Para a utilização da promoção Black Friday, você, Usuário se responsabiliza, com
                                                exclusividade, pelas informações fornecidas, comprometendo-se a incluir apenas informações
                                                legítimas, verdadeiras e atualizadas, eximindo a Zxperiences de qualquer responsabilidade por
                                                quaisquer eventuais danos ou prejuízos decorrentes de informações inverídicas ou incompletas
                                                apresentadas pelo Usuário no momento do cadastro.
                                                2. REGRAS GERAIS
                                                2.1) Ao aceitar estas Regras, você reconhece que não poderá utilizar a Plataforma da campanha
                                                para inserir, divulgar, produzir, reproduzir, replicar, curtir ou compartilhar qualquer conteúdo que:
                                                a) Seja contrário a leis, normas, regulamentos, a moral e/ou os bons costumes geralmente aceitos;
                                                b) Viole direitos de terceiros, como, por exemplo, direitos de propriedade intelectual, direitos à honra,
                                                à vida privada, à imagem, à intimidade pessoal e familiar etc.;
                                                c) Estimule a prática de atos discriminatórios, em razão de sexo, raça, religião, crenças, idade ou
                                                qualquer outra condição, afinal, queremos que a Rede Wi- Fi seja utilizada para a promoção de uma
                                                Internet em que todos se sintam confortáveis;
                                                d) Coloque à disposição ou possibilite o acesso a mensagens, produtos ou serviços ilícitos,
                                                inapropriados, difamatórios, violentos, obscenos, pornográficos e etc.;
                                                e) Quebre o sigilo das comunicações; e/ou
                                                f) Introduza códigos maliciosos aos nossos sistemas, que possam, de alguma forma, trazer dano,
                                                impedir ou dificultar o acesso normal à Rede Wi-Fi ou a qualquer dos equipamentos a ela
                                                conectados, quer sejam de terceiros ou não.

                                                2.2) Você, Usuário, é o único responsável por qualquer atividade exercida por meio da campanha
                                                “Black Friday”. Assim, todos os conteúdos acessados e introduzidos durante seu acesso e quaisquer
                                                danos ou prejuízos a terceiro que possam decorrer deste uso serão de sua responsabilidade.
                                                Ressaltamos também que você é o único responsável pela proteção de seus dispositivos, sistemas,
                                                arquivos e dados contra qualquer atuação indevida ou invasão não autorizada de outros usuários de
                                                Internet, devendo tratar suas credenciais para acesso à rede como pessoais e intransferíveis e
                                                mantendo-as sob sigilo.
                                                2.3) Nós da Zxperiences poderemos bloquear o acesso de qualquer Usuário caso percebamos
                                                qualquer utilização da campanha que contrarie a legislação brasileira outras regras aqui
                                                apresentadas, sem que qualquer indenização seja indevida ao Usuário em razão deste bloqueio e
                                                sem prejuízo à possibilidade da Zxperiences adotar as providências judiais e extrajudiciais cabíveis.
                                                3. ISENÇÃO DE RESPONSABILIDADE
                                                3.1) Nós da Zxperiences não possuímos o dever legal de verificar o conteúdo disponibilizado a
                                                terceiros por você e/ou por outros Usuários a partir do uso da plataforma.
                                                3.2) Aceitando estas Regras, o Usuário assume todos os ônus e as responsabilidades pelos atos e
                                                condutas realizados enquanto utilizar a plataforma, respondendo, ainda, por atos que terceiros
                                                eventualmente praticarem em seu nome, caso utilizarem seu cadastro para acessar e utilizarem a
                                                promoção, em razão de falhas de segurança atribuíveis a você.
                                                3.3) Nós da Zxperiences não nos responsabilizamos, direta ou indiretamente, por quaisquer
                                                despesas, danos ou perdas que derivem, efetiva ou alegadamente, da sua utilização da plataforma.
                                                3.3.1) Caso a Zxperiences seja eventualmente responsabilizada pelo uso indevido da plataforma
                                                “Black Friday” pelo Usuário, este desde já concorda que deverá ressarcir à Zxperiences qualquer
                                                valor gasto em razão deste uso, como, por exemplo, o valor de eventuais indenizações e todos os
                                                custos que derivem de eventuais medidas administrativas ou ações judiciais, inclusive valores
                                                despendidos a título de honorários advocatícios e custas judiciais.
                                                3.4) Por mais que nós da Zxperiences nos esforcemos ao utilizarmos as melhores tecnologias, nem
                                                sempre será possível, em razão da própria natureza da tecnologia, garantir a plena segurança dos
                                                conteúdos transmitidos, difundidos, armazenados, trocados, encaminhados, recebidos, obtidos,
                                                colocados ̀ disposição, ou acessíveis pela utilização da plataforma , nem a proteção completa contra
                                                elementos que possam produzir alterações nos seus equipamentos informáticos ou nos documentos
                                                eletrônicos e pastas armazenadas ou transmitidas a partir do seu dispositivo de acesso. Por isso não
                                                nos responsabilizamos por eventuais danos ou prejuízos de qualquer natureza que possam decorrer
                                                da utilização da campanha, e da plataforma como um todo.
                                                3.5) Também não nos responsabilizamos por atos de terceiros que coletem ou utilizem, por quaisquer
                                                meios, dados cadastrais e informações disponibilizadas por meio da campanha “Black Friday”
                                                3.6) Você, Usuário, concorda que a Zxperiences, não será responsável por quaisquer
                                                indisponibilidades, erros ou falhas apresentados durante seu acesso à campanha “Black Friday”, que
                                                derivem de serviços prestados por terceiros.
                                                4. POLÍTICA DE PRIVACIDADE
                                                4.1) Ao aceitar as presentes Regras, você reconhece que a Zxperiences poderá coletar as
                                                informações inseridas ativamente pelo Usuário no momento do cadastro e, ainda, informações
                                                coletadas automaticamente quando da utilização da rede, como, por exemplo, identificação do
                                                estabelecimento comercial utilizado, IP com data e hora da conexão, entre outras. Além disso, a
                                                Zxperiences poderá promover pesquisas de satisfação e de preferência durante após o cadastro de
                                                suas informações na campanha
                                                4.1.1) Além disso, você reconhece que a Zxperiences poderá empregar tecnologias padrão de coleta
                                                automática de dados, como cookies e pixel tags.

                                                4.2) Você, Usuário também manifesta por meio da aceitação destas regras, seu consentimento livre,
                                                expresso e informado para que nós da Zxperiences utilizemos os dados coletados para aprimorar sua
                                                experiência de navegação na plataforma, bem como compreender melhor as suas preferências, e
                                                avaliar a qualidade e suas impressões sobre nossos produtos. Deixamos claro, ainda, que
                                                poderemos utilizar estes dados para fins publicitários e estatísticos.
                                                4.3) O Usuário reconhece que as informações coletadas poderão ser compartilhadas pela
                                                Zxperiences com: (i) a Parceira e demais empresas parceiras, quando forem necessárias para a
                                                adequada prestação dos serviços objeto de suas atividades; (ii) para proteção dos interesses da
                                                Zxperiences em qualquer tipo de conflito; (iii) no caso de transações e alterações societárias da
                                                Zxperiences; (iv) mediante decisão judicial ou requisição de autoridade competente. Você poderá
                                                solicitar a exclusão dos seus dados coletados pela Zxperiences, pelo e- mail reservas@wals.com.br
                                                4.4) Iremos nos esforçar para respondê-lo no menor tempo possível, respeitando-se os prazos de
                                                guarda estabelecidos pela legislação.
                                                5. DISPOSIÇÕES GERAIS
                                                5.1) Aceitando estas Regras, você declara ser maior de 18 (dezoito) anos, possuindo plena
                                                capacidade jurídica para celebrar e utilizar o serviço objeto destas regras.
                                                5.2) O acesso à plataforma é disponibilizado por prazo indeterminado, de forma que poderemos, a
                                                qualquer momento e independentemente de aviso prévio, suspender ou descontinuar o
                                                funcionamento da plataforma, bem como realizar o bloqueio de acesso de determinado Usuário
                                                específico, caso você, Usuário viole as presentes Regras, situação em que, sob hipótese alguma,
                                                terá o direito a qualquer indenização.
                                                6. LEGISLAÇÃO E FORO
                                                6.1) Estas Regras serão regidas, interpretadas e executadas de acordo com a legislação brasileira,
                                                sendo competente o Foro da Comarca de São Paulo para dirimir qualquer dúvida, questão ou litígio
                                                decorrente deste instrumento. O Usuário consente, expressamente, com a competência desse juízo,
                                                e renuncia, neste ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha
                                                a ser.
                                                Última atualização: 19.11.2019

                                                APRECIE COM MODERAÇÃO. VENDA PROIBIDA PARA MENORES DE 18 ANOS.
                                            </p>
                                        </div>}
                                />
                                <Route
                                    path='/'
                                    render={(props) =>

                                        <div className={''}>
                                            {this.state.birthConsent &&
                                            <AppComponent handleChangeLogin={this.handleChangeLogin} fid={this.state.fid} name={this.state.name} lastName={this.state.lastName} email={this.state.email} uid={this.state.uid} login={this.state.login}/>
                                            }
                                            {!this.state.birthConsent && <BirthdayConsent handleBirthConsent={this.handleBirthConsent}/>}
                                        </div>}
                                />
                            </Switch>



                        </div>
                    </MuiThemeProvider>
                </StylesProvider>
            </Router>
        )
    }
}