import React from 'react';
import PropTypes from 'prop-types';
import Animated from "animated/lib/targets/react-dom";
import Interactable from "react-interactable/noNative";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import firebase from './firebase';
import {withRouter} from "react-router-dom";
import { Route } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SwipeableViews from 'react-swipeable-views';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Cookies from "universal-cookie/es6";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import ReactDOM from 'react-dom';

function mapStyles(styles) {
    return {
        opacity: styles.opacity,
        transform: `translateX(${styles.offset})`,

    };
}

const campaignID = 'walsviral';



function isDate18orMoreYearsOld(day, month, year) {
    return new Date(year+18, month-1, day) <= new Date();
}


function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


let posX = new Animated.Value(0);

let styles = {
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch",
        overflow: "hidden",

    },
    drawerContainer: {
        width: '100vw',
        position: "absolute",
        left: 0,
       /* bottom: 0,*/
        overflow: "hidden",
        zIndex: 3000,
        height: '100vh'
       /* backgroundColor: '#3591FF',*/
    },
    content: {
        backgroundColor: "white",
        flexGrow: 1,
        position: "relative"
    },
    panelContainer: {
        width: window.innerWidth,
        height: 'auto',
        position: "relative",
        transform: 'translateY(calc(100% - 80px))',
        msTransform: 'translateY(-ms-calc(100% - 80px))',
        WebkitTransform: 'translateY(-webkit-calc(100% - 80px))',
        MozTransform: 'translateY(-ms-calc(100% - 80px))',
        OTransform: 'translateY(-o-calc(100% - 80px))',
    },

    leftPanel: {
        width: '100%',
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        maxWidth: '1000px',
        height: "auto",
        zIndex: 2000,
        flex: 1,
        position: "absolute",
        right: 0,
        left: 0,
        margin: 'auto',
        display: 'block',
        backgroundColor: '#447B98',
    },
    successPanel: {
        width: '100%',
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        maxWidth: '1000px',
        height: "calc(100vh - 60px)",
        zIndex: 2000,
        flex: 1,
        position: "absolute",
        right: 0,
        left: 0,
        margin: 'auto',
        display: 'block',
        backgroundColor: '#447B98',
    },
    toggle: {
        fontSize: 30,
    },
    inter: {
        position: 'fixed',
        bottom: 0,
        zIndex: 1400,
    }

};

export default withRouter( class SlideUpPanel extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            panelOpen: false,
            panelHeight: 0,
            toggleStyle: {},
            dni: '',
            birth: '',
            birthSub: false,
            error: false,
            value: 0,
            success: false,
            ID: '',
            name: '',
            lastName: '',
            friendUID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
            submited: false,
            dataPolicy: false,
            sex: '',
            labelWith: 100,
            email: '',
            zone: '',
            registerEmail: '',
            redeemed: false,
            restarted: false,
        };
    }

    onRestart = () =>{


        if (!this.state.redeemed && !this.state.restarted){

            this.setState({
                restarted: true,
            });

            var data = {
                email: this.state.registerEmail,
                campaign: campaignID,
                id: this.state.ID
            };

            fetch('https://us-central1-mktviral.cloudfunctions.net/deleteUser', {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers:{
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                res.json().then(val => {
                    console.log(val);

                    this.toggleOpen();
                    this.loginMailClicl();

                    setTimeout(() => {
                        this.setState({
                            dni: '',
                            birth: '',
                            birthSub: false,
                            error: false,
                            value: 0,
                            success: false,
                            ID: '',
                            name: '',
                            lastName: '',
                            friendUID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
                            submited: false,
                            dataPolicy: false,
                            sex: '',
                            labelWith: 100,
                            email: '',
                            zone: '',
                            registerEmail: '',
                            redeemed: false,
                            restarted: false,
                        });
                    },500);

                    setTimeout(()=>{
                        this.setState({
                            panelHeight: this.panelRef.offsetHeight,
                            toggleStyle: {
                                ...styles.toggle,
                                transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                                WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                            },
                            panelOpen: true,
                        });
                        this.componentRef.snapTo({index:1})
                    },1000);

                }).catch(function (error) {
                    console.log(error)
                });
            }).catch(function (error) {
                console.log(error)
            });
        }





    };

    handleChangeIndex = index => {

        const oldIndex = this.state.value;
        this.setState({
            value: index,
        }, () => {
            if (!this.props.login){
                this.setState({
                    value: 0,
                    error: true,
                });
            }
            else if (index === 2){
                if (!this.state.name || !this.state.lastName ){
                    this.setState({
                        value: oldIndex,
                        error: true,
                    });
                }
            }
            else if (index === 3 &&( oldIndex === 2 || oldIndex === 1)){
                if (!validateEmail(this.state.email)){
                    this.setState({
                        value: oldIndex,
                        error: true,
                    });
                }
            }
            else if(index === 4 &&( oldIndex === 3 || oldIndex === 2)){
                if (!this.state.dni){
                    this.setState({
                        value: oldIndex,
                        error: true,
                    });
                }
            }
            else if(index === 5 &&( oldIndex === 4 || oldIndex === 3)){
                if (!this.state.birth){
                    this.setState({
                        value: oldIndex,
                        error: true,
                    });
                }else{
                    this.setState({
                        birthSub: true,
                    });
                    setTimeout(()=>{
                        this.setState({
                            panelHeight: this.panelRef.offsetHeight,
                            toggleStyle: {
                                ...styles.toggle,
                                transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                                WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                            }

                        });
                        this.componentRef.snapTo({index:1})
                    },500);
                }
            }
            else if (!this.state.success && this.state.value === 6){
                this.setState({
                    value: oldIndex,
                });
            }
            /*else if (index < oldIndex) {
                this.setState({
                    value: oldIndex,
                });
            }*/
        });
    };
    handleChange = name => (event) => {
        var context = this;
        this.setState({
            [name]: event.target.value,
            error: false,
        });
    };

    handleChangeSex = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    toggleRotation = (height) => {
        if (height < 80){
            height = 81;
        }
        return posX.interpolate({
            inputRange: [0, (80 - height) * -1],
            outputRange: [ "0deg", "180deg"]
        })
    };

    loginClick = ()=>{
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithRedirect(provider);
    };
    loginMailClicl = () => {
        this.props.handleChangeLogin();
    };

    /*toggleRotation = posX.interpolate({
        inputRange: [0, (64 - this.state.panelHeight) * -1],
        outputRange: [ "0deg", "180deg"]
    });*/

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged = () =>  {
        console.log("ROUTE CHANGED");
        setTimeout(() => {
            console.log(this.panelRef.offsetHeight);
            this.setState({
                panelHeight: this.panelRef.offsetHeight,
                toggleStyle: {
                    ...styles.toggle,
                    transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                    WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                }

            })
        },1000);

    };


    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.login){
            if (nextProps.login !== this.props.login && nextProps.uid !== this.props.uid ){
                setTimeout(() => {
                    this.toggleOpen();
                    this.setState({ value: 1 });
                    console.log(this.panelRef.offsetHeight);
                    this.setState({
                        panelHeight: this.panelRef.offsetHeight,
                        toggleStyle: {
                            ...styles.toggle,
                            transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                            WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                        }

                    })
                },500);
            }
            else if (nextProps.login !== this.props.login) {
                setTimeout(() => {
                    this.setState({ value: 1 });
                    console.log(this.panelRef.offsetHeight);
                    this.setState({
                        panelHeight: this.panelRef.offsetHeight,
                        toggleStyle: {
                            ...styles.toggle,
                            transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                            WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                        }

                    })
                },500);
            }
        }

        if (nextProps.email){
            this.setState({
                email: nextProps.email,
            })
        }
        if (nextProps.name){
            this.setState({
                name: nextProps.name,
            })
        }
        if (nextProps.lastName){
            this.setState({
                lastName: nextProps.lastName,
            })
        }
    }


    componentDidMount() {

        /*this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });*/

        const cookies = new Cookies();
        var birthday = cookies.get('birthday');
        this.setState({
            birth: birthday
        });

        setTimeout(()=>{
            this.setState({
                panelHeight: this.panelRef.offsetHeight,
                toggleStyle: {
                    ...styles.toggle,
                    transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                    WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                }

            })
        },500);

       this.setState({
           panelHeight: this.panelRef.offsetHeight,
           toggleStyle: {
               ...styles.toggle,
               transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
               WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
           }

       })
    }
    /*toggleStyle = {
        ...styles.toggle,
        transform: [{ rotate: this.toggleRotation() }]
    };*/

    toggleOpen = () => {
        /*this.setState({panelContainerStyle: styles.panelContainerOpened})*/
        if (this.state.panelOpen){
            this.componentRef.snapTo({index: 0});
            this.setState({panelOpen: false})
        } else {
            this.componentRef.snapTo({index: 1});
            this.setState({panelOpen: true})
        }
    };

    onNext = ()=> {
        this.handleChangeIndex(this.state.value + 1);


        if(this.state.value === 5) {

            if (!this.state.submited) {
                if (this.state.dataPolicy){

                    this.setState({
                        submited: true
                    });

                    var db = firebase.firestore();
                    const context = this;
                    let timestamp = firebase.firestore.FieldValue.serverTimestamp();
                    if (this.props.fid) {
                        db.collection('users').where('campaign', '==', campaignID).where('email','==', this.state.email).get()
                            .then(function (snapshot) {
                                if (snapshot.size > 0){
                                    snapshot.forEach(function(doc) {
                                        context.setState({ID: doc.id});
                                        context.setState({
                                            success: true,
                                            value: 6,
                                            registerEmail: doc.data().email,
                                            redeemed: doc.data().validated,
                                        });
                                        setTimeout(()=>{
                                            context.setState({
                                                panelHeight: context.panelRef.offsetHeight - 65,
                                                toggleStyle: {
                                                    ...styles.toggle,
                                                    transform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                    WebkitTransform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                }

                                            });
                                            context.componentRef.snapTo({index:1})
                                        },500);
                                    });

                                }else {

                                    db.collection('users').where('campaign', '==', campaignID).where('fid','==', context.props.fid).get()
                                        .then(function (snapshot) {
                                            if (snapshot.size > 0){
                                                snapshot.forEach(function(doc) {
                                                    context.setState({ID: doc.id});
                                                    context.setState({
                                                        success: true,
                                                        value: 6,
                                                        registerEmail: doc.data().email,
                                                        redeemed: doc.data().validated,
                                                    });
                                                    setTimeout(()=>{
                                                        context.setState({
                                                            panelHeight: context.panelRef.offsetHeight - 65,
                                                            toggleStyle: {
                                                                ...styles.toggle,
                                                                transform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                                WebkitTransform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                            }

                                                        });
                                                        context.componentRef.snapTo({index:1})
                                                    },500);
                                                });

                                            }
                                            else {

                                                db.collection('users').where('campaign', '==', campaignID).where('dni','==', context.state.dni).get()
                                                    .then(function (snapshot) {
                                                        if (snapshot.size > 0){
                                                            snapshot.forEach(function(doc) {
                                                                context.setState({ID: doc.id});
                                                                context.setState({
                                                                    success: true,
                                                                    value: 6,
                                                                    registerEmail: doc.data().email,
                                                                    redeemed: doc.data().validated,
                                                                });
                                                                setTimeout(()=>{
                                                                    context.setState({
                                                                        panelHeight: context.panelRef.offsetHeight - 65,
                                                                        toggleStyle: {
                                                                            ...styles.toggle,
                                                                            transform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                                            WebkitTransform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                                        }

                                                                    });
                                                                    context.componentRef.snapTo({index:1})
                                                                },500);
                                                            });

                                                        }
                                                        else {
                                                            db.collection('users').add({
                                                                name: context.state.name,
                                                                lastName: context.state.lastName,
                                                                email: context.state.email,
                                                                birth: context.state.birth,
                                                                dni: context.state.dni,
                                                                friendUID: context.state.friendUID,
                                                                campaign: campaignID,
                                                                validated: false,
                                                                shareTo: 0,
                                                                shareToRegister: 0,
                                                                fid: context.props.fid,
                                                                createdAt: timestamp,
                                                                sex: context.state.sex,
                                                                zone: context.state.zone
                                                            }).then(function (doc) {
                                                                context.setState({ID: doc.id});
                                                                context.setState({
                                                                    success: true,
                                                                    value: 6,
                                                                    registerEmail: context.state.email,
                                                                });
                                                                setTimeout(()=>{
                                                                    context.setState({
                                                                        panelHeight: context.panelRef.offsetHeight - 65,
                                                                        toggleStyle: {
                                                                            ...styles.toggle,
                                                                            transform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                                            WebkitTransform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                                        }

                                                                    });
                                                                    context.componentRef.snapTo({index:1})
                                                                },500);

                                                                if (context.state.friendUID){
                                                                    var friendRef = db.collection('users').doc(context.state.friendUID);
                                                                    db.runTransaction(function(transaction) {
                                                                        return transaction.get(friendRef).then(function(doc) {
                                                                            if (!doc.exists) {
                                                                                throw "Document does not exist!";
                                                                            }

                                                                            var newShare = doc.data().shareToRegister + 1;
                                                                            transaction.update(friendRef, { shareToRegister: newShare });
                                                                        });
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })

                                            }
                                        });


                                }
                            });
                    }
                    else
                    {
                        db.collection('users').where('campaign', '==', campaignID).where('email','==', this.state.email).get()
                            .then(function (snapshot) {
                                if (snapshot.size > 0){
                                    snapshot.forEach(function(doc) {
                                        context.setState({ID: doc.id});
                                        context.setState({
                                            success: true,
                                            value: 6,
                                            registerEmail: doc.data().email,
                                            redeemed: doc.data().validated,
                                        });
                                        setTimeout(()=>{
                                            context.setState({
                                                panelHeight: context.panelRef.offsetHeight - 65,
                                                toggleStyle: {
                                                    ...styles.toggle,
                                                    transform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                    WebkitTransform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                }

                                            });
                                            context.componentRef.snapTo({index:1})
                                        },500);
                                    });

                                }else {

                                    db.collection('users').where('campaign', '==', campaignID).where('dni','==', context.state.dni).get()
                                        .then(function (snapshot) {
                                            if (snapshot.size > 0){
                                                snapshot.forEach(function(doc) {
                                                    context.setState({ID: doc.id});
                                                    context.setState({
                                                        success: true,
                                                        value: 6,
                                                        registerEmail: doc.data().email,
                                                        redeemed: doc.data().validated,
                                                    });
                                                    setTimeout(()=>{
                                                        context.setState({
                                                            panelHeight: context.panelRef.offsetHeight - 65,
                                                            toggleStyle: {
                                                                ...styles.toggle,
                                                                transform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                                WebkitTransform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                            }

                                                        });
                                                        context.componentRef.snapTo({index:1})
                                                    },500);
                                                });

                                            }
                                            else {
                                                db.collection('users').add({
                                                    name: context.state.name,
                                                    lastName: context.state.lastName,
                                                    email: context.state.email,
                                                    birth: context.state.birth,
                                                    dni: context.state.dni,
                                                    friendUID: context.state.friendUID,
                                                    campaign: campaignID,
                                                    validated: false,
                                                    shareTo: 0,
                                                    shareToRegister: 0,
                                                    createdAt: timestamp,
                                                    sex: context.state.sex,
                                                    zone: context.state.zone
                                                }).then(function (doc) {
                                                    context.setState({ID: doc.id});
                                                    context.setState({
                                                        success: true,
                                                        value: 6,
                                                        registerEmail: context.state.email,
                                                    });
                                                    setTimeout(()=>{
                                                        context.setState({
                                                            panelHeight: context.panelRef.offsetHeight - 65,
                                                            toggleStyle: {
                                                                ...styles.toggle,
                                                                transform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                                WebkitTransform: [{ rotate: context.toggleRotation(context.panelRef.offsetHeight - 65) }],
                                                            }

                                                        });
                                                        context.componentRef.snapTo({index:1})
                                                    },500);

                                                    if (context.state.friendUID){
                                                        var friendRef = db.collection('users').doc(context.state.friendUID);
                                                        db.runTransaction(function(transaction) {
                                                            return transaction.get(friendRef).then(function(doc) {
                                                                if (!doc.exists) {
                                                                    throw "Document does not exist!";
                                                                }

                                                                var newShare = doc.data().shareToRegister + 1;
                                                                transaction.update(friendRef, { shareToRegister: newShare });
                                                            });
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                }
                            });
                    }





                } else {
                    this.setState({
                        error: true,
                    })
                }

            }



        }
    };
    handleChangePolicy = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    render() {
        const theme = createMuiTheme({
            palette: {
              /*  primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#151F2A',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#EDAA00'
                },*/
                  primary: {
                      // light: will be calculated from palette.primary.main,
                      main: '#ffffff',
                      // dark: will be calculated from palette.primary.main,
                      // contrastText: will be calculated to contrast with palette.primary.main
                      contrastText: '#000000'
                  },
                secondary: {
                    main: '#ff0013',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#ffffff'
                },
                error: {
                    main: '#fff',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#ff0013'
                }
            },
            typography: { useNextVariants: true },
        });
        return (
            <MuiThemeProvider theme={theme}>
                        <Interactable.View
                            ref={el => (this.componentRef = el)}
                            animatedValueY={posX}
                            boundaries={{top: 80 - this.state.panelHeight, bottom: 0, bounce: 1}}
                            snapPoints={[{ y: 0 }, { y: 80 -this.state.panelHeight }]}
                            verticalOnly={true}
                            style={styles.inter}
                        >
                            <div style={styles.panelContainer}   >
                                <Paper className={'panel'} style={this.state.success ? styles.successPanel : styles.leftPanel} variant="contained" color="primary" ref={el => (this.panelRef = el)}>
                                    <div onClick={this.toggleOpen}>
                                        <Animated.div  style={this.state.toggleStyle}><i style={{color: '#ffffff'}} className={'ion-md-arrow-dropup'}/></Animated.div>
                                        <p className={'startLoginPTitle'}> {
                                            this.state.success ?
                                             '🕹️ Nós fornecemos' : '🕹️ Vamos começar'
                                        } </p>
                                    </div>
                                        <SwipeableViews
                                            index={this.state.value}
                                            onChangeIndex={this.handleChangeIndex}
                                        >
                                            <div>
                                                <p className={'startLoginPTitle'}> {
                                                    'Escolha um método de entrada'
                                                } </p>
                                                <Button onClick={this.loginClick}  variant="contained" color="primary"
                                                        className={"loginButton modalButton loginStartButton buyButton2 "}>
                                                    FACEBOOK LOGIN
                                                </Button>
                                                <Button onClick={this.loginMailClicl}  variant="contained" color="primary"
                                                        className={"loginButton modalButton loginStartButton buyButton2 "}>
                                                    E-MAIL LOGIN
                                                </Button>

                                            </div>
                                            <div>
                                                <p className={'startLoginPTitle'}> {
                                                    'Digite seu nome e sobrenome'
                                                } </p>
                                                <TextField
                                                    value={this.state.name}
                                                    onChange={this.handleChange('name')}
                                                    error={this.state.error}
                                                    label="Nome"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />
                                                <TextField
                                                    value={this.state.lastName}
                                                    onChange={this.handleChange('lastName')}
                                                    error={this.state.error}
                                                    label="Sobrenome"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            error: 'errorInput',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />

                                            </div>
                                            <div>
                                                <p className={'startLoginPTitle'}> {
                                                    this.props.uid ? 'Confirme seu e-mail' : 'E-mail de confirmação'
                                                } </p>
                                                <TextField
                                                    value={this.state.email}
                                                    onChange={this.handleChange('email')}
                                                    error={this.state.error}
                                                    label="E-mail"
                                                    type="email"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />

                                            </div>

                                            <div>
                                                <p className={'startLoginPTitle'}> {
                                                    'Digite seu CPF e sexo'
                                                } </p>
                                                <TextField
                                                    value={this.state.dni}
                                                    onChange={this.handleChange('dni')}
                                                    error={this.state.error}
                                                    label="CPF"
                                                    type="number"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />
                                                <FormControl variant="outlined" className={'inputCustomLogin fieldModal loginDetails'}>
                                                    <InputLabel
                                                        ref={ref => {
                                                            this.InputLabelRef = ref;
                                                        }}
                                                        htmlFor="outlined-age-simple"
                                                    >
                                                        Sexo
                                                    </InputLabel>
                                                    <Select
                                                        value={this.state.sex}
                                                        onChange={this.handleChange('sex')}
                                                        inputProps={{
                                                            classes: {
                                                                root: 'inputCustomLogin',
                                                                select: 'inputCustomLogin',
                                                            },
                                                        }}
                                                        input={
                                                            <OutlinedInput
                                                                className={'inputCustomLogin'}
                                                                labelWidth={40}
                                                            />
                                                        }
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value={'female'}>Feminino</MenuItem>
                                                        <MenuItem value={'male'}>Masculino</MenuItem>
                                                        <MenuItem value={'non'}>Não identifica</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div>
                                                <p className={'startLoginPTitle'}> {
                                                    'Digite sua data de nascimento'
                                                } </p>
                                                <TextField
                                                    value={this.state.birth}
                                                    onChange={this.handleChange('birth')}
                                                    error={this.state.error}
                                                    label=""
                                                    type="date"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <p className={'startLoginPTitle'}> {
                                                    'TERMOS E CONDIÇÕES'
                                                } </p>
                                                {this.state.birthSub &&
                                                <p className={'dataPolicyText'}>
                                                    O participante declara que as informações fornecidas, incluindo os dados pessoais são legítimos,
                                                    verdadeiros e atualizados.
                                                    <br/>• Os dados pessoais e demais informações fornecidas pelo participante nesta promoção
                                                    estará sujeito a tratamento automatizado e incorporado a base de dados administrada pela
                                                    Zxperiences encarregada de desenvolver a campanha promocional.
                                                    <br/>• As informações fornecidas será protegida mediante o uso de tecnologias e procedimentos
                                                    de segurança evitando o acesso, revelação e uso não autorizado
                                                    <br/>• Os participantes receberão um e-mail. Uma vez recebido, nenhum participante e/ou
                                                    Beneficiário poderão participar novamente da promoção, mesmo que receba outro e-mail.
                                                    <br/>• O intuito da Promoção é o Participante ganhar o prêmio e indicar um amigo (Beneficiários)
                                                    para que este ganhe o Prêmio. Participante e Beneficiários deverão ser maiores de 18 anos,
                                                    sem obrigação de compra e de forma gratuita, desde que apresente o e-mail e documento
                                                    de identidade válido ao atendente do bar.
                                                    <br/>• O e-mail poderá ser trocado pelo Prêmio apenas dentro do Período de Participação (27/11
                                                    até 01/12) e somente no endereço indicado
                                                </p>
                                                }
                                                <div className={'dataPolicyContainer'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.dataPolicy}
                                                                onChange={this.handleChangePolicy('dataPolicy')}
                                                                value='dataPolicy'
                                                                color="primary"
                                                                classes={{
                                                                    root: 'dataPolicy',
                                                                    checked: 'dataPolicyChecked',
                                                                }}
                                                            />
                                                        }
                                                        label="Eu aceito termos e condições"
                                                        className={'dataPolicy'}
                                                    />
                                                </div>


                                            </div>
                                            <div>
                                                {this.state.success &&
                                                <p className={'startLoginPTitle successTitle'}> {
                                                    'Enviamos seu chope para  ' + this.state.registerEmail +
                                                    '. Verifique e resgate sua cerveja em nosso bar. Você já sabe com quem está indo? Convide seus amigos.'
                                                } </p>}
                                                {/*{this.state.success && <img className={'successImage'} src={successImage}/>}*/}


                                                {this.state.success &&
                                                <Button variant="contained" color="primary"
                                                        className={"loginButton modalButton loginStartButton buyButton2 successButton "}
                                                         href={"https://api.whatsapp.com/send?text=" + this.state.name +
                                                         '%2C%20quer%20compartilhar%20um%20chope%20gr%C3%A1tis%20com%20voc%C3%AA%20para%0Aaproveitar%20a%20Black%20Friday.%20Acesse%20o%20link%20para%20garantir%20seu%20chope.%20https%3A%2F%2Fwals.getviral.me%2F' + this.state.ID}
                                                        >
                                                    compartilhe com seus amigos
                                                </Button>}
                                               {/* <Button variant="contained" color="primary"
                                                        className={"loginButton modalButton loginStartButton buyButton2 successButton "}
                                                        href={"http://www.cervezapatagonia.com.ar/refugios"}
                                                >
                                                    ELEGÍ TU REFUGIO
                                                </Button>*/}
                                                {this.state.success &&
                                                <p className={'startLoginPTitle successTitle'}> {
                                                    'Se ' + this.state.registerEmail +' não for seu e-mail ou estiver incorreto, clique aqui:'
                                                } </p>}
                                                {this.state.success &&
                                                <Button onClick={this.onRestart} variant="contained" color="secondary"
                                                        className={"loginButton modalButton loginStartButton buyButton2 successButton "}
                                                        >
                                                    {this.state.restarted && <CircularProgress color={'primary'} />}
                                                    {!this.state.restarted && 'começar de novo'}

                                                </Button>}

                                            </div>
                                        </SwipeableViews>
                                        {!this.state.success &&
                                        <div className={'bottomContainer'}>
                                            <div style={{marginLeft: '5%'}} className={this.state.value === 0 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 1 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 2 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 3 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 4 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 5 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            {this.props.login &&
                                            <Button onClick={this.onNext} color="primary" className={'nextButton'}>

                                                {
                                                    this.state.value < 5 && 'Próximo'
                                                }
                                                {
                                                    this.state.value === 5 && !this.state.submited && 'Concluir'
                                                }
                                            </Button>}

                                            {this.props.login && this.state.submited && this.state.value === 5 &&
                                            <CircularProgress className={'nextButton progressCircular'} />
                                           }

                                        </div>
                                        }

                                </Paper>
                            </div>
                        </Interactable.View>
            </MuiThemeProvider>

        )
    }
})
